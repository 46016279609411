import { createUseStyles } from 'react-jss'

import { aspectRatio } from '../../theme'


export default createUseStyles({
  container: {
    position: 'relative',
    width: '100%',
  },
  ratioContainer: {
    ...aspectRatio(2, 3),
  },
  cards: {
    width: '90% !important',
    height: '90% !important',
    cursor: (props) => (props.onCardClick ? 'pointer' : 'default'),
    userSelect: 'none',
    '-webkit-tap-highlight-color': 'transparent',
  },
  card: {
    borderRadius: '0 !important',

    '&.cardslider__card--index-0': {
      '& $gradient': {
        opacity: 0,
        transition: 'none',
      },

      '& $overlay': {
        opacity: 0,
        transition: 'none',
      },
    },

    '&.cardslider__card--index-1': {
      '& $overlay': {
        opacity: 0.25,
      },
    },

    '&.cardslider__card--index-2': {
      '& $overlay': {
        opacity: 0.5,
      },
    },

    '&.cardslider__card--index-3': {
      '& $overlay': {
        opacity: 0.75,
      },
    },

    '&.cardslider__card--index-4': {
      '& $overlay': {
        opacity: 1,
      },
    },

    '&.cardslider__card--index-5': {
      '& $overlay': {
        opacity: 1,
      },
    },
  },
  img: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  gradient: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '40%',
    background: 'linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%)',
    opacity: 1,
    transition: 'opacity 0.3s 0.3s',
  },
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'white',
    opacity: 0,
    transition: 'opacity 0.3s 0s',
  },
})

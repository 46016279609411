import React, { useCallback, useMemo } from 'react'
import { graphql } from 'gatsby'
import * as PropTypes from 'prop-types'
import { navigate } from 'gatsby-plugin-intl'

import { withMemo, withCountdown } from '../../decorators'
import Layout from '../../components/Layout'
import LooksTemplate from '../../templates/Looks'
import router from '../../router'
import routes from '../../router/routes'
import * as appConfiguration from '../../configuration'



const Looks = (props) => {
  const { pageContext, data, location } = props

  const onClose = useCallback(() => {
    navigate(router(routes.home.path))
  }, [])

  const onHeaderLogoClick = useCallback(() => {
    navigate(router(routes.home.path))
  }, [])

  const looksTemplateProps = useMemo(() => ({
    layoutProps: {
      seo: {
        lang: pageContext?.language ?? null,
        title: data?.contentfulLooks?.seo?.title ?? '',
        description: data?.contentfulLooks?.seo?.description?.description ?? '',
        image: appConfiguration.app.url + data?.contentfulLooks?.seo?.picture?.localFile?.childImageSharp?.fluid?.src ?? '',
        url: location?.href ?? '',
      },
    },
    looksProps: {
      looks: data?.contentfulLooks?.content?.map((look) => ({
        id: look?.id ?? null,
        fluid: look?.picture?.localFile?.childImageSharp?.fluid ?? null,
        // route: router(pageContext?.isVip ? routes.vipGridPreset.path : routes.gridPreset.path, { slug: look.slug }),
        embedUrl: look?.modelUrl ?? '',
        title: look?.title ?? '',
      })),
    },
  }), [pageContext.language, data.contentfulLooks.seo.title, data.contentfulLooks.seo.description.description, data.contentfulLooks.seo.picture.localFile.childImageSharp.fluid.src, data.contentfulLooks.content, location.href])

  return (
    <Layout
      {...looksTemplateProps.layoutProps}
    >
      <LooksTemplate
        {...looksTemplateProps.looksProps}
        onHeaderCloseClick={onClose}
        onHeaderLogoClick={onHeaderLogoClick}
      />
    </Layout>
  )
}

Looks.propTypes = {
  // eslint-disable-next-line
  data: PropTypes.object,
  // eslint-disable-next-line
  pageContext: PropTypes.object,
  // eslint-disable-next-line
  location: PropTypes.object,
}
Looks.defaultProps = {
  data: null,
  pageContext: null,
  location: null,
}

export default withCountdown(withMemo()(Looks))

export const query = graphql`
  query looks($language: String){
    contentfulLooks(node_locale: { eq: $language }) {
      ...balenciagaF21Looks
    }
    contentfulConfiguration(node_locale: { eq: $language }) {
      ...balenciageF21Configuration
    }
  }
`

import { createUseStyles } from 'react-jss'

import { colors, desktop, fonts } from '../../theme'
import { headerHeight } from '../../components/Header/styles'


export default createUseStyles({
  container: {
    height: '100%',
    background: colors.pureWhite,
    paddingTop: headerHeight,
  },
  wrapper: {
    display: 'flex',
    flexFlow: 'column',
    height: '100%',
  },
  header: {
    position: 'fixed',
    width: '100%',
    top: 0,
    left: 0,
    right: 0,
    color: colors.pureBlack,
    backgroundColor: colors.pureWhite,
  },
  headerRight: {
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      marginLeft: '2rem',
    },
    '& svg': {
      width: '1.6rem',
      height: '1.6rem',
    },
  },
  lookGrid: {
    paddingTop: '2rem',
  },
  stacksMode: {
    flex: 1,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '3rem',
  },
  cardSlider: {
    width: '100%',
    maxWidth: 'calc((2/3) * (100vh - 15rem))', // keep 2:3 ratio and contain on viewport
  },
  cardNavigation: {
    ...fonts.UniversBoldCondensed,
    textTransform: 'uppercase',
    display: 'flex',
    alignItems: 'center',
    fontSize: '1.6rem',
    userSelect: 'none',
  },
  arrowLeft: {
    display: 'none',
    position: 'relative',
    top: '-0.1rem',
    padding: '2rem 1.5rem',
    ...desktop({
      display: 'initial',
    }),
    '& svg': {
      width: '1.5rem',
      height: '1.5rem',
    },
  },
  arrowRight: {
    display: 'none',
    position: 'relative',
    top: '-0.2rem',
    padding: '2rem 1.5rem',
    ...desktop({
      display: 'initial',
    }),
    '& svg': {
      width: '1.5rem',
      height: '1.5rem',
    },
  },
})

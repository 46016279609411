export const EVENTS = {
  LOOKBOOK_GRID_VIEW_CLICK: 'LOOKBOOK_GRID_VIEW_CLICK',
  LOOKBOOK_CAROUSEL_VIEW_CLICK: 'LOOKBOOK_CAROUSEL_VIEW_CLICK',
  LOOK_CLICK: 'LOOK_CLICK',
  LOOK_CHANGE: 'LOOK_CHANGE',
  PLAY_VIDEO: 'PLAY_VIDEO',
  PRESS_KIT_CLICK: 'PRESS_KIT_CLICK',
  PRESS_KIT_DOWNLOAD: 'PRESS_KIT_DOWNLOAD',
}


export const trackEvent = (eventName, data = {}) => {
  if (window?.dataLayer && eventName) {
    console.log('trackEvent', { event: eventName, ...data })
    window.dataLayer.push({ event: eventName, ...data })
  }
}

import React, { useEffect, useRef } from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import $ from 'jquery'

import Image from '../Image'

import '../../lib/JqueryCardSlider/index.css'
import '../../lib/JqueryCardSlider'

import useStyles from './styles'


const CardSlider = (props) => {
  const classes = useStyles(props)
  const {
    className,
    onCardChange,
    cardIndex,
    onCardClick,
    cards,
  } = props

  // REFS

  const $cardSlider = useRef(null)

  // EFFECTS

  useEffect(() => {
    $('.card-slider')
      .cardslider({
        // 'up', 'down', 'right', 'left'
        direction: 'down',
        nav: false,
        loop: false,
        dots: false,
        beforeCardChange: null,
        afterCardChange: onCardChange,
        showCards: undefined,
      })

    $cardSlider.current = $('.card-slider')
      .data('cardslider')
  }, [onCardChange])

  useEffect(() => {
    if ($cardSlider.current) {
      $cardSlider.current.changeCardTo(cardIndex)
    }
  }, [cardIndex])

  // RETURN

  return (
    <div
      className={cx(classes.container, className)}
    >
      <div className={classes.ratioContainer}>
        <div className="card-slider">
          <ul
            className={classes.cards}
            onClick={onCardClick}
          >
            {cards.map((card, index) => (
              <li
                key={index}
                className={classes.card}
              >
                {card.fluid && (
                  <Image
                    img={card.fluid}
                    className={classes.img}
                    alt=""
                  />
                )}
                {card.image && (
                  <img
                    src={card.image}
                    className={classes.img}
                    alt=""
                  />
                )}
                <div className={classes.gradient} />
                <div className={classes.overlay} />
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  )
}

CardSlider.propTypes = {
  className: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  cards: PropTypes.any,
  cardIndex: PropTypes.any,
  onCardChange: PropTypes.func,
  onCardClick: PropTypes.func,
}

CardSlider.defaultProps = {
  className: null,
  cards: [],
  cardIndex: 0,
  onCardChange: null,
  onCardClick: null,
}

export default CardSlider

import cx from 'classnames'
import PropTypes from 'prop-types'
import React, { useEffect, useRef, useState, useCallback } from 'react'
import { gsap, Power1 } from 'gsap'
import { SwitchTransition, Transition } from 'react-transition-group'

import { EVENTS, trackEvent } from '../../utils/tracking'
import withMemo from '../../decorators/withMemo'
import LookGrid from '../../components/LookGrid'
import Icon from '../../components/Icon'
import { iconsKeys } from '../../components/Icon/Icon.assets'
import Header from '../../components/Header'
import CardSlider from '../../components/CardSlider'

import useStyles from './styles'


export const modes = {
  grid: 'grid',
  stacks: 'stacks',
}

const LooksTemplate = (props) => {
  const classes = useStyles(props)
  const {
    className,
    looks,
    onHeaderCloseClick,
    onHeaderLogoClick,
  } = props

  // REFS

  const $animatedWrapper = useRef()

  // STATES

  const [mode, setMode] = useState(modes.grid)

  const [isGridModeVisible, setIsGridModeVisible] = useState(mode === modes.grid)
  const [isStacksModeVisible, setIsStacksModeVisible] = useState(mode === modes.stacks)
  const [selectedLook, setSelectedLook] = useState(0)

  // ANIMATIONS

  const enterAnimation = useCallback(() => {
    const timeline = gsap.timeline({ delay: 0 })

    if ($animatedWrapper.current) {
      const animatedChildren = Array
        .from($animatedWrapper.current.children)
        .filter((child) => !child.classList.contains('is-notstaggered'))

      timeline.fromTo(animatedChildren, {
        opacity: 0,
      }, {
        duration: 0.5,
        opacity: 1,
        stagger: 0.6,
        ease: Power1.easeIn,
      })
    }
  }, [])

  const exitAnimation = useCallback((onCompleteCallback) => {
    const timeline = gsap.timeline({ onComplete: onCompleteCallback })

    if ($animatedWrapper.current) {
      timeline.to($animatedWrapper.current, {
        duration: 0.3,
        opacity: 0,
        ease: Power1.easeIn,
      })
    }
  }, [])

  const gridAnimationEndListener = useCallback((node, done) => {
    const timeline = gsap.timeline({ onComplete: done })

    if (isGridModeVisible) {
      timeline.to('.look', {
        duration: 0.3,
        opacity: 0,
        ease: 'none',
        stagger: {
          from: selectedLook,
          each: 0.07,
          grid: 'auto',
        },
      })
    }

    timeline.fromTo(node, {
      opacity: isGridModeVisible ? 1 : 0,
    }, {
      duration: 0.5,
      opacity: isGridModeVisible ? 0 : 1,
      ease: Power1.easeIn,
    }, isGridModeVisible ? '-=0.5' : '>')
  }, [isGridModeVisible, selectedLook])

  const stacksAnimationEndListener = useCallback((node, done) => {
    gsap.fromTo(node, {
      opacity: isStacksModeVisible ? 1 : 0,
    }, {
      duration: 0.5,
      opacity: isStacksModeVisible ? 0 : 1,
      ease: Power1.easeIn,
      onComplete: done,
    })
  }, [isStacksModeVisible])

  // HANDLERS

  const handleOnLookClick = useCallback(({
    index,
    id,
    title,
  }) => {
    setSelectedLook(index)
    trackEvent(EVENTS.LOOK_CLICK, {
      index,
      id,
      title,
    })
    setTimeout(() => {
      setMode(modes.stacks)
    }, 20)
  }, [])

  const handleOnCloseClick = useCallback(() => {
    exitAnimation(onHeaderCloseClick)
  }, [exitAnimation, onHeaderCloseClick])

  const handleOnLogoClick = useCallback(() => {
    exitAnimation(onHeaderLogoClick)
  }, [exitAnimation, onHeaderLogoClick])

  const handleOnStackIconClick = useCallback(() => {
    setMode(modes.stacks)
    trackEvent(EVENTS.LOOKBOOK_CAROUSEL_VIEW_CLICK)
  }, [])

  const handleOnGridIconClick = useCallback(() => {
    setMode(modes.grid)
    trackEvent(EVENTS.LOOKBOOK_GRID_VIEW_CLICK)
  }, [])

  const handleOnArrowLeftClick = useCallback(() => {
    if (selectedLook > 0) {
      setSelectedLook(((prevState) => prevState - 1))
      const newLook = looks[selectedLook - 1]

      trackEvent(EVENTS.LOOK_CHANGE, {
        index: selectedLook - 1,
        id: newLook.id,
        title: newLook.title,
      })
    }
  }, [looks, selectedLook])

  const handleOnArrowRightClick = useCallback(() => {
    if (selectedLook < looks.length - 1) {
      setSelectedLook(((prevState) => prevState + 1))
      const newLook = looks[selectedLook + 1]

      trackEvent(EVENTS.LOOK_CHANGE, {
        index: selectedLook + 1,
        id: newLook.id,
        title: newLook.title,
      })
    }
  }, [selectedLook, looks])

  const handleOnCardClick = useCallback(() => {
    handleOnArrowRightClick()
  }, [handleOnArrowRightClick])

  // EFFECTS

  useEffect(() => {
    enterAnimation()
  }, [enterAnimation])

  // RETURN

  return (
    <main
      className={cx(className, classes.container)}
      ref={$animatedWrapper}
    >
      <div className={classes.wrapper}>
        <Header
          className={classes.header}
          hasBottomGradient
          rightContent={(
            <div className={classes.headerRight}>
              {mode === modes.grid && (
                <Icon
                  icon={iconsKeys.Stacks}
                  onClick={handleOnStackIconClick}
                />
              )}
              {mode === modes.stacks && (
                <Icon
                  icon={iconsKeys.Grid}
                  onClick={handleOnGridIconClick}
                />
              )}
              <Icon
                icon={iconsKeys.Close2}
                onClick={handleOnCloseClick}
              />
            </div>
          )}
          onLogoClick={handleOnLogoClick}
        />
        <SwitchTransition>
          {mode === modes.grid ? (
            <Transition
              key="grid"
              onEntered={() => setIsGridModeVisible(true)}
              onExited={() => setIsGridModeVisible(false)}
              addEndListener={gridAnimationEndListener}
            >
              {looks && (
                <LookGrid
                  className={classes.lookGrid}
                  lookClassName="look"
                  looks={looks}
                  onLookClick={handleOnLookClick}
                />
              )}
            </Transition>
          ) : (
            <Transition
              key="stacks"
              onEntered={() => setIsStacksModeVisible(true)}
              onExited={() => setIsStacksModeVisible(false)}
              addEndListener={stacksAnimationEndListener}
            >
              <div className={classes.stacksMode}>
                <CardSlider
                  cards={looks}
                  cardIndex={selectedLook}
                  className={classes.cardSlider}
                  onCardClick={handleOnCardClick}
                />
                <div className={classes.cardNavigation}>
                  <Icon
                    icon={iconsKeys.ArrowLeft}
                    onClick={handleOnArrowLeftClick}
                    className={classes.arrowLeft}
                  />
                  <p>{looks?.[selectedLook]?.title}</p>
                  <Icon
                    icon={iconsKeys.ArrowRight}
                    onClick={handleOnArrowRightClick}
                    className={classes.arrowRight}
                  />
                </div>
              </div>
            </Transition>
          )}
        </SwitchTransition>
      </div>
    </main>
  )
}

export const LooksTemplatePropTypes = {
  className: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  looks: PropTypes.any,
  onHeaderCloseClick: PropTypes.func,
  onHeaderLogoClick: PropTypes.func,
}

LooksTemplate.propTypes = LooksTemplatePropTypes

LooksTemplate.defaultProps = {
  className: null,
  looks: null,
  onHeaderCloseClick: () => {
  },
  onHeaderLogoClick: () => {
  },
}

export default withMemo()(LooksTemplate)

import { createUseStyles } from 'react-jss'

import { colors, desktop } from '../../theme'


export default createUseStyles({
  container: {
    width: '100%',
  },
  wrapper: {
    overflow: 'hidden',
    position: 'relative',
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(16rem, 1fr))',
    gridTemplateRows: '1fr',
    borderTop: `solid 1px ${colors.pureBlack} `,
    ...desktop({
      gridTemplateColumns: 'repeat(auto-fit, minmax(23rem, 1fr))',
    }),
  },
  look: {
    position: 'relative',
    display: 'flex',
    cursor: 'pointer',
    '&::after, &::before': {
      content: '""',
      position: 'absolute',
      zIndex: 2,
      pointerEvents: 'none',
    },
    '&::after': {
      top: '-1px',
      bottom: '-1px',
      left: '100%',
      width: '1px',
      background: colors.pureBlack,
    },
    '&::before': {
      top: '100%',
      left: 0,
      right: 0,
      height: '1px',
      background: colors.pureBlack,
    },
    '&:last-child': {
    },
    '&:hover': {
      '& $image img': {
        transform: 'scale(1.01)',
      },
    },
  },
  image: {
    overflow: 'hidden',
    width: '100%',
    height: '100%',
    '&:not(.is-notstaggered)': {
      opacity: 0, // to prevent FOUC
    },
    '& img': {
      transform: 'scale(1.04)',
      transition: 'transform 0.25s ease !important',
      objectFit: 'cover',
    },
  },
})
